<template>
  <v-app>

    <appbar></appbar>
    <v-main>
      <v-container class="grey lighten-5 ma-0 px-0">
        <v-row justify="center">
          <v-col cols="4" class="pa-3 ma-3 d-flex justify-center">
            <v-btn
            :disabled="beginnerActive"
            @click="beginnerClicked()"
            >Beginner</v-btn>
          </v-col>
          <v-col cols="4" class="pa-3 ma-3 d-flex justify-center">
            <v-btn
            :disabled="!beginnerActive"
            @click="advancedClicked()"
            >Advanced</v-btn>
          </v-col>
        </v-row>
        <loader :active="loaderActive" message="Daten werden geladen" />
        <v-container v-if="!loaderActive" class="leaderboard-container">
          <league-table :athleteData="leagueDataList"></league-table>
        </v-container>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import Loader from './Loader.vue';
import loaderMixin from '../mixins/loader';
import LeagueTable from './LeagueTable.vue';
import Appbar from './Appbar.vue';

export default {
  name: 'Home',
  title: 'CaliX - League',
  metaInfo: {
    title: 'CaliX League',
  },
  components: {
    Loader,
    LeagueTable,
    Appbar,
  },
  mixins: [loaderMixin],
  loading: true,

  data: () => ({
    leagueDataListRaw: [],
    leagueDataList: [],
    leagueDataBeginner: [],
    leagueDataAdvanced: [],
    beginnerActive: true,
    timer: null,
    //
  }),
  mounted() {
    // eslint-disable-next-line no-console
    this.getLeagueData();
  },
  created() {
    this.intervalUpdate();
  },
  methods: {
    beginnerClicked() {
      this.beginnerActive = true;
      this.leagueDataList = [];
      this.getLeagueData();
    },
    advancedClicked() {
      this.beginnerActive = false;
      this.leagueDataList = [];
      this.getLeagueData();
    },
    intervalUpdate() {
      this.tiemr = setInterval(() => {
        this.getLeagueData();
      }, 5000);
    },
    getLeagueData() {
      if (this.leagueDataList.length === 0) {
        this.showLoader();
      }
      fetch('https:///api.calix-league.de/league/beginner')
        .then((response) => response.json())
        .then((data) => {
          this.leagueDataListRaw = data;
          this.leagueDataList = [];
          this.leagueDataBeginner = [];
          this.leagueDataAdvanced = [];
          this.leagueDataListRaw.forEach((element, index) => {
            const athlete = {
              Id: index,
              Name: element.Name,
              Score: element.Score,
              Level: element.Level,
              Skills: {
                'Frog Stand': element['Frog Stand'],
                Handstand: element.Handstand,
                'Pull Up': element['Pull Up'],
                'Muscle Up': element['Muscle Up'],
                'Push Up': element['Push Up'],
                'Core Push Up': element['Core Push Up'],
                Hanging: element.Hanging,
                'L-Sit': element['L-Sit'],
              },
            };
            switch (athlete.Level) {
              case 'Beginner':
                this.leagueDataBeginner.push(athlete);
                break;

              case 'Advanced':
                this.leagueDataAdvanced.push(athlete);
                break;
              default:
                break;
            }
          });

          // sort both for score
          this.leagueDataBeginner.sort((a, b) => b.Score - a.Score);
          this.leagueDataAdvanced.sort((a, b) => b.Score - a.Score);

          this.leagueDataList = this.beginnerActive
            ? this.leagueDataBeginner
            : this.leagueDataAdvanced;

          if (this.loaderActive) {
            this.hideLoader();
          }
        });
    },
  },
  beforeDestroy() {
    clearInterval(this.timer);
  },
};
</script>
