<template>
    <div class="ma-0 pa-0">
    <DataRow
            v-for="athlete in this.athleteData"
            :key="athlete.Id"
            justify="center"
            class="score-row my-1 py-0"
            :athlete="athlete"
          >
        </DataRow>
        </div>
</template>

<script>

import DataRow from './DataRow.vue';

export default {
  name: 'LeagueTable',
  props: {
    athleteData: Array,
  },
  components: { DataRow },

};
</script>
