<template>
    <v-app-bar app color="black" dark fixed>
      <div class="d-flex align-center">
        <v-img alt="CaliX Logo"
        class="shrink mr-2" contain src="../assets/logo_white.png" transition="scale-transition"
          width="40" />
      </div>
      <v-spacer></v-spacer>
      <v-app-bar-title>
        CaliX League Beta
      </v-app-bar-title>

      <v-spacer></v-spacer>

    </v-app-bar>
</template>

<script>
export default {
  name: 'AppBar',
};
</script>
