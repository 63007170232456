<template>
    <v-row
    justify="center"
    >
    <v-col
        cols="12"
        sm="12"
        md="8"
        lg="6"
        class="my-0 pa-0"
        @click="overlayClicked()"
        >
              <v-card
              outlined
              tile
              style="height: 50px;"
              class="d-flex align-center my-0 py-0"

              >
                <v-row class="px-4 ma-0">
                  <v-col cols="6" class="d-flex justify-start">
                    <span class="not-selectable">{{athlete.Name}}</span>
                  </v-col>
                  <v-col cols="6" class="d-flex justify-end">
                    <span class="not-selectable">{{athlete.Score}} Punkte</span>
                  </v-col>
                  <v-overlay
                    :absolute="absolute"
                    :value="overlay"
                    :opacity="opacity"
                    class="my-overlay"
                    >
                    <v-row
                    justify="center"
                    align-content="center"
                    class="px-0"
                    >
                        <v-col
                        v-for="(value, skill) in skillsFiltered" :key="athlete.Id+' '+skill+value">
                            <span class="not-selectable small-text">{{skill}}</span>
                            <br>
                            <span class="not-selectable small-text">{{value}}</span>
                        </v-col>
                    </v-row>
                    </v-overlay>
                </v-row>

              </v-card>
            </v-col>
        </v-row>
</template>

<script>
export default {
  name: 'DataRow',
  data: () => ({
    absolute: true,
    overlay: false,
    opacity: 0.95,
  }),
  props: {
    athlete: {
      type: Object,
      default: (() => {}),
    },
  },
  computed: {
    skillsFiltered() {
      const filtered = {};
      Object.entries(this.athlete.Skills).forEach(([key, value]) => {
        if (value !== '') {
          filtered[key] = value;
        }
      });
      return filtered;
    },
  },
  methods: {
    overlayClicked() {
      this.overlay = !this.overlay;
    },
  },
};
</script>

<style scoped>

.score-row:nth-child(1) .v-card{
  background-color: #F2CC44;
  min-height: 80px;
}
.score-row:nth-child(2) .v-card{
  background-color: #A5A5A5;
  min-height: 75px;

}

.score-row:nth-child(3) .v-card{
  background-color: #EAB578;
  min-height: 70px;

}

.not-selectable{
    -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
}

.small-text{
    font-size: 0.7em;
}
.my-overlay:deep(.v-overlay__content) {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
  }

</style>
